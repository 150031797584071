import { useNavigate } from "react-router";
import { PAGE_URL } from "../../constants";
import { crm } from "../../store/cabinetApi/crm";
import { Company } from "../../store/cabinetApi/generated/crm";
import CRMCompanies from "./Companies";
import { createSearchParams } from "react-router-dom";
import useCompaniesList from "../../components/CRM/CRMCompanies/companiesListHook";


const pageSize = 20;

const CRMCompaniesContainer = () => {
  const companiesListData = useCompaniesList({ pageSize: 20 });

  // TODO: remove limit from this request. Only adding it to get the right response shape for now
  const { data: orgCategories } = crm.useAppCrmCompanyCategoriesListQuery({ limit: 1000 });
  const [createCompany, { isLoading: isCreatingContactOrg }] = crm.useAppCrmCompaniesCreateMutation();

  const handleCreateCompany = async (organization: Company) => {
    await createCompany(organization).unwrap();
  };

  const navigate = useNavigate();

  const handleSearch = (search: string) => {
    navigate({
      pathname: PAGE_URL.CRM_SEARCH,
      search: createSearchParams({ search }).toString(),
    });
  };

  return <CRMCompanies
    companiesListData={companiesListData}
    orgPageSize={pageSize}
    orgCategories={orgCategories?.data || []}
    onCreateCompany={handleCreateCompany}
    isCreatingContactOrg={isCreatingContactOrg}
    onSearch={handleSearch}
  />;
};

export default CRMCompaniesContainer;
