import { Box, Theme, Typography, useMediaQuery } from "@mui/material";
import { isLargeFormatDevice } from "../../utils/screenSizeUtils";
import { CabButton } from "@CabComponents/CabButton";
import CabinetPage from "../../components/Common/CabinetPage";
import { useState } from "react";
import { Company, CompanyCategory } from "../../store/cabinetApi/generated/crm";
import NewOrganizationModal from "./NewOrganizationModal";
import colors from "../../colors";
import CRMCompaniesTable from '../../components/CRM/CRMCompanies/Companies';
import SearchInputForm, { SearchForm } from "../../components/CRM/SearchInputForm";
import useCompaniesList from "../../components/CRM/CRMCompanies/companiesListHook";


const CRMCompanies = ({
  companiesListData, orgPageSize, orgCategories, onCreateCompany, isCreatingContactOrg, onSearch
}: {
  companiesListData: ReturnType<typeof useCompaniesList>;
  orgCategories: CompanyCategory[];
  orgPageSize: number;
  onCreateCompany: (organization: Company) => Promise<void>;
  isCreatingContactOrg: boolean;
  onSearch: (search: string) => void;
}) => {
  const [openNewOrganizationModal, setOpenNewOrganizationModal] = useState(false);
  const isSmDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const handleSearch = ({ search }: SearchForm) => {
    onSearch(search);
  };

  return (
    <CabinetPage
      pageName="Companies"
      headerContent={
        <Box width='100%' display='flex' justifyContent='space-between'>
          <Typography variant="h1">
            Companies
          </Typography>
          {!isSmDown && (
            <SearchInputForm
              onSearch={handleSearch}
              sx={{ flex: 1, maxWidth: 500, paddingLeft: 4, paddingRight: 4 }}
            />
          )}
          {isLargeFormatDevice() && (
            <CabButton
              onClick={() => setOpenNewOrganizationModal(true)}
            >
              New Company
            </CabButton>
          )}
        </Box>
      }
    >
      <>
        <Box padding={2} display="flex" flexDirection="column" flex={1} gap={2}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body1" color={colors.black700}>
              Companies and organizations
            </Typography>
            {isSmDown && (
              <SearchInputForm onSearch={handleSearch} />
            )}
          </Box>

          <Box position="relative" height="100%">
            <Box position="absolute" top={0} bottom={0} left={0} right={0}>
              <CRMCompaniesTable
                {...companiesListData}
                interactive
                paginate
                sx={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
              />
            </Box>
          </Box>
        </Box>

        <NewOrganizationModal
          open={openNewOrganizationModal}
          onClose={() => setOpenNewOrganizationModal(false)}
          organizationCategories={orgCategories}
          onCreateCompany={onCreateCompany}
          isCreatingContactOrg={isCreatingContactOrg}
        />
      </>
    </CabinetPage>
  );
};

export default CRMCompanies;
